<template>
  <v-menu eager>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" aria-label="Locale Switcher" v-if="$i18n.availableLocales.length > 1" class="rounded-lg">
        <img :src="utils.getImgUrl(`locales/${$i18n.locale}.png`)" class="locale-image" alt="locale switcher">
      </v-btn>
    </template>
    <v-list
      bg-color="light-background"
      class="rounded-xl">
      <v-list-item
          class="pa-2"
          v-for="(locale, index) in $i18n.availableLocales"
          :key="index"
          :active="$i18n.locale === locale"
          @click="changeLocale(locale)"
      >
        <v-list-item-title class="d-flex align-center">
          <img :src="utils.getImgUrl(`locales/${locale}.png`)" class="locale-image mr-2" :alt="locale">
          <span>{{ $t(`locales.${locale}`) }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>

import { useStore } from 'vuex';

const store = useStore();

const changeLocale = (locale) => {
  store.commit('setLocale', locale);
};
</script>

<style scoped>
.locale-image {
  width: 24px;
  height: 18px;
}
</style>
