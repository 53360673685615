const WidgetOverview = () => import('@/views/widgets/Widgets');
const WidgetEdit = () => import('@/views/widgets/WidgetsEdit');
const WidgetPreview = () => import('@/views/widgets/WidgetPreview');

const routes = [
  {
    path: '',
    name: 'Widgets',
    redirect: to => ({ name: 'WidgetsSaved' }),
  },
  {
    path: 'saved',
    name: 'WidgetsSaved',
    meta: {
      breadcrumb: 'widget.my-widgets',
    },
    component: WidgetOverview,
  },
  {
    path: 'gallery',
    name: 'WidgetsGallery',
    meta: {
      breadcrumb: 'widget.gallery',
    },
    component: WidgetOverview,
  },
  {
    path: 'creator',
    name: 'WidgetsCreator',
    meta: {
      breadcrumb: 'Creator',
    },
    component: WidgetOverview,
  },
  {
    path: 'edit/:schemeId/:id?',
    name: 'WidgetsEdit',
    component: WidgetEdit,
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: 'preview/:id',
    name: 'WidgetDetail',
    meta: {
      hideNavigation: true,
    },
    component: WidgetPreview,
  },
];

export default routes;
