<template>
  <v-app id="v-app">
    <v-navigation-drawer
      v-if="!noDrawer"
      v-model="drawer"
      :model-value="mobileDrawer ? drawer : true"
      :rail-width="65"
      :rail="!drawerExpanded && !mobileDrawer"
      class="dark-background"
      :temporary="mobileDrawer"
      location="start"
    >
      <kicklet-navigation
        :minified="!drawerExpanded"
        :editing-as="!noEditingAs"
        @close="clickDrawer"
      />
    </v-navigation-drawer>
    <v-main>
      <alert-system/>
      <div class="content dark-background">
        <v-app-bar id="v-app-bar" class="glass-background">
          <div class="d-flex align-center action w-100">
            <div class="d-flex align-center justify-end mr-2 w-100">
              <v-btn
                v-if="!noDrawer"
                class="ml-1"
                :icon="mobileDrawer ? 'mdi-menu' : (drawerExpanded ? 'mdi-menu-close' : 'mdi-menu-open')"
                @click="clickDrawer"
              ></v-btn>
              <v-breadcrumbs v-if="!noDrawer && !mobileDrawer" :items="breadcrumbs">
                <template v-slot:title="{ item }">
                  <template v-if="item.dynamic">
                    <dynamic-breadcrumb :title="item.title" />
                  </template>
                  <template v-else>
                    {{ item.translated ? $t(`${item.title}`) : item.title }}
                  </template>
                </template>
              </v-breadcrumbs>
              <v-app-bar-title>
                <v-list-item v-if="noDrawer || mobileDrawer" @click="$router.push('/')" style="width: fit-content;" class="rounded-lg">
                  <template #prepend>
                    <img class="banner" width="30px" height="30px" alt="banner" :src="utils.getImgUrl('logo.png')" />
                  </template>
                  <template #title>
                    <span
                      class="banner-font color-primary ml-2"
                    >KICKLET</span>
                  </template>
                </v-list-item>
              </v-app-bar-title>
              <locale-switcher />
              <collaborator-switcher v-if="!$vuetify.display.xs && !noDrawer && !noEditingAs" />
              <template v-if="!noDrawer">
                <v-btn v-if="store.getters.isLoggedIn" icon="mdi-logout" @click="logoutDialogModel.display = true;"></v-btn>
                <v-btn v-else icon="mdi-login" @click="discord.login()"></v-btn>
              </template>
            </div>
          </div>
        </v-app-bar>
        <router-view/>
      </div>
    </v-main>
    <kicklet-footer />
    <logout-dialog
      v-model="logoutDialogModel"
      @confirm="logout"
    />
    <error-dialog v-model="errorShowing" v-if="error" :title="error.title" :text="error.text" :data="error.data" />
  </v-app>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import i18n from '@/i18n';
import KickletFooter from '@/components/custom/kicklet/KickletFooter.vue';
import { useStore } from 'vuex';
import ErrorDialog from '@/components/ErrorDialog';
import KickletNavigation from '@/components/custom/kicklet/KickletNavigation.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import AlertSystem from './components/AlertSystem.vue';
import { useRoute, useRouter } from 'vue-router';
import { Backend } from '@/services/Backend';
import LogoutDialog from '@/components/dialog/LogoutDialog';
import { useDisplay } from 'vuetify';
import { Utils } from '@/modules/utils';
import CollaboratorSwitcher from '@/components/CollaboratorSwitcher';
import DynamicBreadcrumb from '@/components/DynamicBreadcrumb';

const store = useStore();
const router = useRouter();
const route = useRoute();

const display = useDisplay();

const routerReady = ref(false);
router.isReady()
  .then(() => {
    routerReady.value = true;
  });

const drawer = ref(false);
const drawerExpanded = ref(true);

const pathname = computed(() => {
  if (routerReady.value) {
    return route.path;
  }
  return window.location.pathname;
});

const mobileDrawerPaths = [
  '/',
];
const mobileDrawer = computed(() => {
  return mobileDrawerPaths.some(pattern => Utils.matchWildcard(pattern, pathname.value)) || display.mobile.value;
});

const noDrawerPaths = [
  '/user/*',
];
const noDrawer = computed(() => {
  return noDrawerPaths.some(pattern => Utils.matchWildcard(pattern, pathname.value)) || route.meta.hideNavigation;
});

const noEditingAsPaths = [
  '/collaborators',
  '/profile/*',
];
const noEditingAs = computed(() => {
  return noEditingAsPaths.some(pattern => Utils.matchWildcard(pattern, pathname.value));
});

const breadcrumbs = computed(() => {
  const crumbs = [];
  const matchedRoutes = route.matched;

  matchedRoutes.forEach((route, index) => {
    const crumb = route.meta.breadcrumb;
    if (crumb !== false) {
      crumbs.push({
        title: crumb || route.name,
        disabled: index === matchedRoutes.length - 1,
        to: route.path,
        translated: !!crumb,
        dynamic: !!route.meta.breadcrumbDynamic,
      });
    }
  });

  return crumbs;
});

const error = computed(() => store.getters.error);
const errorShowing = computed({
  get () {
    return store.getters.errorShowing;
  },
  set (val) {
    store.commit('setErrorShowing', val);
  },
});

const locale = computed(() => store.getters.locale || navigator.language || navigator.userLanguage);
const setLang = (locale) => {
  const availableLocales = i18n.global.availableLocales;
  const normalizedLocale = locale.split('-')[0];

  const isLocaleAvailable = availableLocales.includes(normalizedLocale);
  const finalLocale = isLocaleAvailable ? normalizedLocale : 'en';

  document.documentElement.setAttribute('lang', finalLocale);
  i18n.global.locale = finalLocale;
};

function clickDrawer () {
  if (mobileDrawer.value) {
    drawer.value = !drawer.value;
  } else {
    drawerExpanded.value = !drawerExpanded.value;
  }
}

const logoutDialogModel = ref({
  display: false,
});

function logout () {
  Backend.logout()
    .then(() => {
      router.push('/');
    });
}

watch(locale, (newVal) => {
  setLang(newVal);
});

watch(mobileDrawer, () => {
  if (mobileDrawer.value) {
    drawer.value = false;
  }
});

onMounted(() => {
  setLang(locale.value);
});

</script>

<style>
#v-app, .v-overlay-container {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: normal;
}
#v-app-bar {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.glass-background {
  backdrop-filter: blur(10px);
  background: rgba(var(--v-theme-dark-background), 0.5) !important;
}

.avatar-border{
  border: 2px solid rgb(var(--v-theme-primary));
}
.video-container {
  border: 4px solid rgb(var(--v-theme-light-background));
}
.dark-background{
  background-color: rgb(var(--v-theme-dark-background));
}
.light-background{
  background-color: rgb(var(--v-theme-light-background));
}
.lighter-background{
  background-color: rgb(var(--v-theme-lighter-background));
}
.emoji-xs{
  font-size: 14px;
}
.emoji{
  font-size: 18px;
}
.emoji-big{
  font-size: 35px;
}
h1, h2, h3, h4, h5, .h0 {
  color: rgb(var(--v-theme-primary)) !important;
}

h1 {
  margin-bottom: 10px;
}

.h0 {
  font-weight: bolder;
  font-size: 52px;
}

.title-1 {
  font-weight: bolder;
  font-size: 32px;
  color: white;
}

.sub-title-1 {
  font-weight: bolder;
  font-size: 32px;
  color: white;
  opacity: 0.5;
}

.title-2 {
  font-weight: bolder;
  font-size: 24px;
  color: white;
}

.sub-title-2 {
  font-weight: bolder;
  font-size: 24px;
  color: white;
  opacity: 0.5;
}

.title-3 {
  font-weight: bolder;
  font-size: 16px;
  color: white;
}

.sub-title-3 {
  font-weight: bolder;
  font-size: 16px;
  color: white;
  opacity: 0.5;
}

.title-4 {
  font-weight: bolder;
  font-size: 14px;
  color: white;
}

.sub-title-4 {
  font-weight: bolder;
  font-size: 14px;
  color: white;
  opacity: 0.5;
}

.title-5 {
  font-weight: bolder;
  font-size: 12px;
  color: white;
}

.sub-title-5 {
  font-weight: bolder;
  font-size: 12px;
  color: white;
  opacity: 0.5;
}

iframe {
  color-scheme: light;
}

.fill-height-content {
  height: calc(100vh - 120px);
}

.max-height-content {
  max-height: calc(100vh - 120px);
}

.v-application__wrap {
  background: rgb(var(--v-theme-dark-background));
}

.color-primary {
  color: rgb(var(--v-theme-primary)) !important;
}

.x50 {
  width: 50px;
  height: 50px;
}

.clickable {
  cursor: pointer;
}

.bold-primary {
  font-weight: bold;
  color: rgb(var(--v-theme-primary));
}

.v-list-item__content {
  width: 100%;
}

.info {
  opacity: 0.6;
}

.banner-font {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -3px;
}

.v-tooltip > .v-overlay__content {
  background: rgba(var(--v-theme-surface-variant), 0.85) !important;
}

.animated-card {
  transition: transform 0.2s ease-in-out, background-color 0.05s ease-in-out, background-size 0.2s ease-in-out;
  user-select: none;
  background-size: 100% !important;
}

.animated-card:hover {
  transform: scale(1.01);
  background: linear-gradient(45deg, rgb(var(--v-theme-primary)), rgb(var(--v-theme-primary)) 30%, #D0F28A 60%) !important;
  background-size: 200% !important;
  background-position: 0% !important;
}

.animated-card > .v-card {
  box-shadow: none !important;
  background-color: rgba(var(--v-theme-light-background), 0.85) !important;
  backdrop-filter: blur(10px) !important;
}

.non-selectable {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* WebKit (Safari/Chrome) browsers */
  -moz-user-select: none; /* Mozilla browsers */
  -ms-user-select: none; /* Internet Explorer/Edge browsers */
}

tbody tr:nth-of-type(even) {
  background-color: rgba(var(--v-theme-light-background), 0.3);
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(var(--v-theme-lighter-background), 0.3);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse-animation{
  animation: pulse 3s infinite;
}

.pulse:hover {
  animation: none;
}

.aspect-16-9-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625 or 56.25%) */
}

/* Webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(var(--v-theme-dark-background), 0.85);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(var(--v-theme-primary));
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--v-theme-primary), 0.85);
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(var(--v-theme-primary)) rgba(var(--v-theme-dark-background), 0.85);
}
</style>

<style scoped>
@font-face {
  font-family: 'Anja Eliane';
  src: url('~@/assets/fonts/Anja Eliane.woff2') format('woff2');
}
@font-face {
  font-family: 'BebasNeue';
  src: url('~@/assets/fonts/BebasNeue.woff2') format('woff2');
}
@font-face {
  font-family: 'Bypass';
  src: url('~@/assets/fonts/Bypass.woff2') format('woff2');
}
@font-face {
  font-family: 'Cheesecake';
  src: url('~@/assets/fonts/Cheesecake.woff2') format('woff2');
}
@font-face {
  font-family: 'Malinda';
  src: url('~@/assets/fonts/Malinda.woff2') format('woff2');
}
@font-face {
  font-family: 'Minecrafter';
  src: url('~@/assets/fonts/Minecrafter.woff2') format('woff2');
}
@font-face {
  font-family: 'Nexa Heavy';
  src: url('~@/assets/fonts/Nexa Heavy.woff2') format('woff2');
}
@font-face {
  font-family: 'Berlin Sans';
  src: url('~@/assets/fonts/Berlin_Sans_FB_Demi_Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  src: url('~@/assets/fonts/internal/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300; /* Light */
}

@font-face {
  font-family: 'Poppins';
  src: url('~@/assets/fonts/internal/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
}

@font-face {
  font-family: 'Poppins';
  src: url('~@/assets/fonts/internal/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
}

@font-face {
  font-family: 'Poppins';
  src: url('~@/assets/fonts/internal/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600; /* Semi-Bold */
}

@font-face {
  font-family: 'Poppins';
  src: url('~@/assets/fonts/internal/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
}
@media screen and (min-width: 600px) {
  .sm-login {
    display: none;
  }
}
@media screen and (max-width: 601px) {
  .lg-login {
    display: none;
  }
}
.highlighted-username {
  color: rgb(var(--v-theme-primary));
}
</style>
