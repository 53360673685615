import { axiosInstance } from '@/services/Service';
import store from '@/store';

export class Overlay {
  static http = axiosInstance;

  static userId () {
    return store.getters.userId;
  }

  static selectedUserId () {
    return this.http.selectedUserId();
  }

  static getWidgetCategories () {
    return this.http.get('/overlay/marketplace/category');
  }

  static getWidgetTags () {
    return this.http.get('/overlay/marketplace/tag');
  }

  static getWidgetSchemes (page = 1, pageSize = 10, search = '', categories = [], tags = []) {
    return this.http.get('/overlay/marketplace/widget', {
      params: {
        page,
        pageSize,
        search,
        categories: categories.join(','),
        tags: tags.join(','),
      },
    });
  }

  static getTrendingWidgets () {
    return this.http.get('/overlay/marketplace/widget/trending');
  }

  static getWidgetScheme (id) {
    return this.http.get(`/overlay/marketplace/widget/${id}`);
  }

  static postWidgetCategory (category) {
    return this.http.post('/overlay/marketplace/creator/category', category);
  }

  static putWidgetCategory (id, category) {
    return this.http.put(`/overlay/marketplace/creator/category/${id}`, category);
  }

  static postWidgetTag (tag) {
    return this.http.post('/overlay/marketplace/creator/tag', tag);
  }

  static putWidgetTag (id, tag) {
    return this.http.put(`/overlay/marketplace/creator/tag/${id}`, tag);
  }

  static postWidgetScheme (scheme) {
    return this.http.post('/overlay/marketplace/creator/widget', scheme);
  }

  static putWidgetScheme (id, scheme) {
    return this.http.put(`/overlay/marketplace/creator/widget/${id}`, scheme);
  }

  static getGenericOverlay (id) {
    return this.http.get(`/overlay/${this.selectedUserId()}/${id}`);
  }

  static listGenericOverlays () {
    return this.http.get(`/overlay/${this.selectedUserId()}`);
  }

  static postGenericOverlay (overlay) {
    return this.http.post(`/overlay/${this.selectedUserId()}`, overlay);
  }

  static putGenericOverlay (id, overlay) {
    return this.http.put(`/overlay/${this.selectedUserId()}/${id}`, overlay);
  }

  static deleteGenericOverlay (id) {
    return this.http.delete(`/overlay/${this.selectedUserId()}/${id}`);
  }

  static getOverlayByHash (hash) {
    return this.http.get(`/overlay/hash/${hash}`);
  }

  static getShopOverlayHash () {
    return this.http.get(`/overlay/${this.selectedUserId()}/shop/hash`);
  }

  static getSongRequestList () {
    return this.http.get(`/song/${this.selectedUserId()}`);
  }

  static getPublicSongRequests (userId) {
    return this.http.get((`/song/public/${userId}`));
  }

  static postSong (videoSearch) {
    return this.http.post(`/song/${this.selectedUserId()}`, {
      videoSearch,
    });
  }

  static deleteSong (songId) {
    return this.http.delete(`/song/${this.selectedUserId()}/${songId}`);
  }

  static skipSong () {
    return this.http.delete(`/song/${this.selectedUserId()}/skip`);
  }

  static clearSongs () {
    return this.http.delete(`/song/${this.selectedUserId()}/clear`);
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          overlay: Overlay,
        };
      },
    });
  },
};
