import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';

import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { VTreeview } from 'vuetify/lib/labs/VTreeview';

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components: {
    VTreeview,
  },
  theme: {
    defaultTheme: 'default',
    themes: {
      default: {
        dark: true,
        colors: {
          primary: '#97CB2A',
          primary2: '#11a624',
          'dark-background': '#1C1C1C',
          'light-background': '#252525',
          'lighter-background': '#2e2e2e',
          surface: '#1C1C1C',
          'on-primary': '#ffffff',
          secondary: '#909090',
          accent: '#bda565',
          error: '#cb564e',
          warning: '#ad7643',
          info: '#33738c',
          success: '#4e9553',
          yellow: '#f5d02d',
          orange: '#ffa94c',
          blue: '#5f9fe0',
          premium1: '#ffd500',
          premium2: '#ffa200',
          discord: '#5865F2',
        },
      },
    },
  },
});

export default vuetify;
