export default {
  "accept-tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die Nutzungsbedingungen"])},
  "add-to-stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Stream hinzufügen"])},
  "admin.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "advanced-editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterter Editor"])},
  "assets.delete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["das Asset"])},
  "assets.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
  "audit-logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Logs"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "backend.errors.BOT_COMMAND_ALIAS_DUPLICATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dein Alias <span class='color-primary'>", _interpolate(_named("alias")), "</span> ist bereits ein bestehender Befehl"])},
  "backend.errors.BOT_COMMAND_ALIAS_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einer deiner Aliase ist leer"])},
  "backend.errors.BOT_COMMAND_ALIAS_SELF_DUPLICATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dein Alias <span class='color-primary'>", _interpolate(_named("alias")), "</span> ist derselbe wie dein Befehl"])},
  "backend.errors.BOT_COMMAND_MIN_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Befehl erfordert mindestens eine Berechtigung"])},
  "backend.errors.already canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Subscription ist bereits beendet und läuft nur noch solange, wie du bereits bezahlt hast."])},
  "backend.errors.command already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast bereits einen anderen Befehl mit diesem Namen"])},
  "backend.errors.config limit exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichenlimit überschritten."])},
  "backend.errors.forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast keine Berechtigung, diese Aktion auszuführen"])},
  "backend.errors.giveaway already ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Giveaway ist bereits beendet"])},
  "backend.errors.invalid JSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten."])},
  "backend.errors.limit exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast das Limit erreicht."])},
  "backend.errors.max storage size exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datei kann nicht hochgeladen werden, da das Speicherlimit dadurch überschritten wird."])},
  "backend.errors.moderation rule name already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Regel mit diesem Namen existiert bereits"])},
  "backend.errors.no entries to roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Einträge zum Auslosen"])},
  "backend.errors.premium only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feature ist aktuell nur für Premium User."])},
  "backend.errors.user dont activate collaboration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer hat die Zusammenarbeit nicht aktiviert"])},
  "backend.errors.validation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Validation failed! ", _interpolate(_named("messages"))])},
  "backend.errors.version already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Version existiert bereits"])},
  "bot.alert-messages.FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgen"])},
  "bot.alert-messages.HOST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosten"])},
  "bot.alert-messages.SUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
  "bot.alert-messages.SUB_GIFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschenkte Abonnements"])},
  "bot.alert-messages.UNFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfolgen"])},
  "bot.alert-messages.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht aktivieren / deaktivieren"])},
  "bot.alert-messages.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort"])},
  "bot.alert-messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alarmnachrichten"])},
  "bot.command-group.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Befehlsgruppe - ", _interpolate(_named("name"))])},
  "bot.command-groups.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Befehlsgruppe aktivieren / deaktivieren"])},
  "bot.command-groups.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe erstellen"])},
  "bot.command-groups.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["die Befehlsgruppe <span class='color-primary'>", _interpolate(_named("name")), "</span> und alle enthaltenen Befehle"])},
  "bot.command-groups.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlsgruppe"])},
  "bot.command-groups.edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe bearbeiten"])},
  "bot.command-groups.fmt-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Befehlsgruppen (", _interpolate(_named("current")), "/", _interpolate(_named("max")), ")"])},
  "bot.command-groups.labels.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "bot.command-groups.labels.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "bot.command-groups.max-groups.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst nicht mehr Befehlsgruppen haben"])},
  "bot.command-groups.max-groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann nicht erstellt werden"])},
  "bot.command-groups.no-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast derzeit keine Gruppen. Beginne damit, eine neue zu erstellen."])},
  "bot.command-groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlsgruppen"])},
  "bot.command.errors.optional-after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Argumente danach sollten erforderlich sein!"])},
  "bot.command.errors.required-before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Argumente davor müssen erforderlich sein!"])},
  "bot.command.labels.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
  "bot.command.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Befehl - !", _interpolate(_named("command"))])},
  "bot.commands.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Befehl aktivieren / deaktivieren"])},
  "bot.commands.aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliase"])},
  "bot.commands.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl erstellen"])},
  "bot.commands.custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefiniert"])},
  "bot.commands.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["den Befehl <span class='color-primary'>", _interpolate(_named("name")), "</span>"])},
  "bot.commands.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl"])},
  "bot.commands.detail-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Befehl - ", _interpolate(_named("name"))])},
  "bot.commands.fmt-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Befehle (", _interpolate(_named("current")), "/", _interpolate(_named("max")), ")"])},
  "bot.commands.import-from-marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importiere vom Marktplatz"])},
  "bot.commands.marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktplatz"])},
  "bot.commands.no-aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aliase"])},
  "bot.commands.no-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast derzeit keine Befehle in dieser Gruppe. Beginne damit, einen neuen zu erstellen."])},
  "bot.commands.permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
  "bot.commands.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehle"])},
  "bot.creator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersteller"])},
  "bot.default-commands.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardbefehle"])},
  "bot.gallery.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
  "bot.global-messages.NOT_ENOUGH_POINTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht genügend Punkte"])},
  "bot.global-messages.NO_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Berechtigung"])},
  "bot.global-messages.PERIOD_NOT_REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl Abklingzeit"])},
  "bot.global-messages.USABILITY_NOT_MATCHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Live-Status nicht übereinstimmend"])},
  "bot.global-messages.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
  "bot.global-messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Nachrichten"])},
  "bot.marketplace-creator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktplatz-Ersteller"])},
  "bot.marketplace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktplatz"])},
  "bot.moderation-rule.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel aktivieren / deaktivieren"])},
  "bot.moderation.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel erstellen"])},
  "bot.moderation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])},
  "bot.periodic-messages.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nachricht aktivieren / deaktivieren"])},
  "bot.periodic-messages.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht erstellen"])},
  "bot.periodic-messages.delete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diese periodische Nachricht"])},
  "bot.periodic-messages.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodische Nachricht"])},
  "bot.periodic-messages.edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht bearbeiten"])},
  "bot.periodic-messages.fmt-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Periodische Nachrichten (", _interpolate(_named("current")), "/", _interpolate(_named("max")), ")"])},
  "bot.periodic-messages.max-groups.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst nicht mehr Befehlsgruppen haben"])},
  "bot.periodic-messages.max-groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann nicht erstellt werden"])},
  "bot.periodic-messages.no-periodic-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast derzeit keine periodischen Nachrichten. Beginne damit, eine neue zu erstellen."])},
  "bot.periodic-messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodische Nachrichten"])},
  "bot.points.points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte"])},
  "bot.points.points-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktebezeichnung"])},
  "bot.points.points-per-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte pro Nachricht"])},
  "bot.points.points-per-watchtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte pro Minute Zuschauerzeit"])},
  "bot.points.subs-multiplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnenten-Multiplikator"])},
  "bot.points.subscriber-bonus-points": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deine Abonnenten erhalten Punkte x", _interpolate(_named("multiplier")), "."])},
  "bot.points.subscriber-same-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Abonnenten erhalten genauso viele Punkte wie jeder andere Zuschauer."])},
  "bot.points.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktesystem"])},
  "bot.song-requests.no-songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden noch keine Lieder hinzugefügt. Deine Zuschauer können sie mit dem Befehl !sr (YT-URL) hinzufügen."])},
  "bot.song-requests.now-playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIRD ABGESPIELT:"])},
  "bot.song-requests.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Musikanfragen (", _interpolate(_named("min")), "/", _interpolate(_named("max")), ")"])},
  "bot.song-requests.title-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musikanfragen"])},
  "bot.songrequest-command.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren / Deaktivieren des Songrequest-Befehls"])},
  "bot.stats.charts.chat-activities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat-Aktivitäten"])},
  "bot.stats.charts.command-executions-pie.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlsausführungen"])},
  "bot.stats.charts.command-executions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlsausführungen"])},
  "bot.stats.errors.too-much-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu viele Daten. Versuche, einen kleineren Bereich oder ein größeres Intervall auszuwählen."])},
  "bot.stats.no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten"])},
  "bot.stats.no-data-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starte deinen ersten Livestream, um Statistiken über deine Streams zu sammeln."])},
  "bot.stats.range.month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 4 Wochen"])},
  "bot.stats.range.week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 7 Tage"])},
  "bot.stats.range.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Jahr"])},
  "bot.stats.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken"])},
  "bot.stats.viewer.edit.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuschauer bearbeiten"])},
  "bot.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Bot"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
  "chat-downloader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Downloader"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Kasse"])},
  "clear-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles löschen"])},
  "click-an-emote-to-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf ein Emote, um es zu kopieren"])},
  "collaborators.dialog.add.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter hinzufügen"])},
  "collaborators.dialog.delete-managed-users.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span>"])},
  "collaborators.dialog.delete-managed-users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltenden Benutzer"])},
  "collaborators.dialog.edit.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bearbeite die Berechtigungen für <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span>."])},
  "collaborators.dialog.edit.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter bearbeiten"])},
  "collaborators.dialog.permissions.alert-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertnachrichten"])},
  "collaborators.dialog.permissions.audit-log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit-Log"])},
  "collaborators.dialog.permissions.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehle"])},
  "collaborators.dialog.permissions.counter-variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zählervariablen"])},
  "collaborators.dialog.permissions.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "collaborators.dialog.permissions.danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚠️ Gefahr"])},
  "collaborators.dialog.permissions.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "collaborators.dialog.permissions.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "collaborators.dialog.permissions.execute-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code ausführen"])},
  "collaborators.dialog.permissions.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
  "collaborators.dialog.permissions.giveaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giveaway"])},
  "collaborators.dialog.permissions.global-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Nachrichten"])},
  "collaborators.dialog.permissions.kick-identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identität"])},
  "collaborators.dialog.permissions.moderation-chat-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderationsregeln"])},
  "collaborators.dialog.permissions.overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overlays"])},
  "collaborators.dialog.permissions.periodic-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodische Nachrichten"])},
  "collaborators.dialog.permissions.shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "collaborators.dialog.permissions.song": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musikanfragen"])},
  "collaborators.dialog.permissions.stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken"])},
  "collaborators.dialog.permissions.stream-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Titel"])},
  "collaborators.dialog.permissions.test-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test-Events"])},
  "collaborators.dialog.permissions.toggle-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umschalten"])},
  "collaborators.dialog.permissions.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "collaborators.dialog.permissions.variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variablen"])},
  "collaborators.dialog.permissions.view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansehen"])},
  "collaborators.dialog.remove.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "collaborators.dialog.remove.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
  "collaborators.dialog.remove.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bist du sicher, dass du <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span> als Mitarbeiter entfernen möchtest?"])},
  "collaborators.dialog.remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter entfernen"])},
  "collaborators.managed.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutze Kicklet als"])},
  "collaborators.menutitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenarbeit"])},
  "collaborators.table.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "collaborators.table.created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
  "collaborators.table.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "collaborators.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
  "collaborators.toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Benutzer können mich als Mitarbeiter hinzufügen"])},
  "command-editor.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl"])},
  "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
  "counter-variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zähler-Variable"])},
  "counter-variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zählervariablen"])},
  "coupon-optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschein (optional)"])},
  "dashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "dialog.abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "dialog.are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher?"])},
  "dialog.buy.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt kaufen"])},
  "dialog.buy.inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die erforderlichen Informationen ein"])},
  "dialog.buy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel kaufen"])},
  "dialog.cancel-premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kündigung wird die Premium-Funktionen nach diesem Abrechnungszeitraum beenden. Bei Fragen oder für Hilfe kontaktieren Sie uns bitte auf Discord. Bestätigen Sie unten, um fortzufahren. Vielen Dank!"])},
  "dialog.command-config.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl konfigurieren"])},
  "dialog.command-move.select-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlsgruppe"])},
  "dialog.command-move.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl verschieben"])},
  "dialog.confirm.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "dialog.confirm.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie fortfahren möchten? Diese Aktion kann nicht rückgängig gemacht werden."])},
  "dialog.confirm.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion bestätigen"])},
  "dialog.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren"])},
  "dialog.delete.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "dialog.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bist du sicher, dass du ", _interpolate(_named("item")), " dauerhaft löschen möchtest?"])},
  "dialog.delete.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " löschen"])},
  "dialog.giveaway.chat-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat-Nachricht"])},
  "dialog.giveaway.costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten"])},
  "dialog.giveaway.entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag"])},
  "dialog.giveaway.inactivity-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivitäts-Timeout"])},
  "dialog.giveaway.keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselwort"])},
  "dialog.giveaway.output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe"])},
  "dialog.giveaway.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giveaway"])},
  "dialog.logout.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "dialog.logout.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du dich wirklich abmelden?"])},
  "dialog.logout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "dialog.moderation-rule.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "dialog.moderation-rule.action-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "dialog.moderation-rule.ignore-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groß- und Kleinschreibung ignorieren"])},
  "dialog.moderation-rule.ignore-emote-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Emote ignorieren"])},
  "dialog.moderation-rule.min-characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestanzahl Zeichen"])},
  "dialog.moderation-rule.min-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestanzahl Nachrichten"])},
  "dialog.moderation-rule.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "dialog.moderation-rule.similar-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ähnlichkeitsprüfung"])},
  "dialog.moderation-rule.similar-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozentsatz"])},
  "dialog.moderation-rule.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel Einstellungen"])},
  "dialog.moderation-rule.trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auslöser"])},
  "dialog.moderation-rule.trigger-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "dialog.moderation-rule.within-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitfenster"])},
  "dialog.ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "dialog.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "dialog.shop.custom-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Felder"])},
  "dialog.shop.item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeldetails"])},
  "dialog.shop.item-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelaktion"])},
  "dialog.shop.item-config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelkonfiguration"])},
  "discard-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen verwerfen"])},
  "discord.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "docs.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentation"])},
  "downloader.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
  "downloader.end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ende: ", _interpolate(_named("convertSegmentToTime"))])},
  "downloader.length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länge:"])},
  "downloader.messages-downloaded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nachrichten heruntergeladen: ", _interpolate(_named("messagesDownloaded"))])},
  "downloader.quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualität"])},
  "downloader.start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start: ", _interpolate(_named("convertSegmentToTime"))])},
  "downloader.stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
  "downloader.time-elapsed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verstrichene Zeit: ", _interpolate(_named("formatVideoTime"))])},
  "downloader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel:"])},
  "downloader.url-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie eine gültige Kick-Video-URL ein"])},
  "downloader.video-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videodetails:"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "editing-as-username": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bearbeiten als ", _interpolate(_named("username"))])},
  "emote-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Emote wurde in deine Zwischenablage kopiert."])},
  "emotes.buttons.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
  "emotes.buttons.generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
  "emotes.labels.font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftart"])},
  "emotes.labels.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "emotes.labels.text-shadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schatten"])},
  "emotes.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emote Generator"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "event.follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
  "event.gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschenktes Abo"])},
  "event.host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "event.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abo"])},
  "event.unfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow"])},
  "extension-advantages": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klarer Chat: Deaktiviert Kicklet-Nachrichten für bessere Lesbarkeit."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlsübersicht: Zeigt alle Befehle mit \"!\"."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserte Interaktion: Steigert die Zuschauerbeteiligung."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerfreundlich: Einfach für Zuschauer."])}
  ],
  "extension.features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorteile"])},
  "extension.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browsererweiterung"])},
  "file.invalid-type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Datei <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span> ist kein gültiger Dateityp!"])},
  "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
  "forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für immer"])},
  "form.addcollab.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.addcollab.permissions.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
  "form.command-group.description.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "form.command-group.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.command.aliases.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliase"])},
  "form.command.aliases.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliase"])},
  "form.command.aliases.single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
  "form.command.arguments.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argumente"])},
  "form.command.arguments.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benannte Argumente"])},
  "form.command.arguments.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.command.arguments.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderlich"])},
  "form.command.asResponse.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Antwort"])},
  "form.command.availability.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
  "form.command.category.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "form.command.changelog.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungsprotokoll"])},
  "form.command.command.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl"])},
  "form.command.command.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl"])},
  "form.command.config.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
  "form.command.config.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration"])},
  "form.command.costs.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten"])},
  "form.command.description.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "form.command.examples.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiele"])},
  "form.command.examples.single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel"])},
  "form.command.globalPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Periode"])},
  "form.command.image.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "form.command.messageResponse.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlsantwort"])},
  "form.command.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.command.permissions.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
  "form.command.template.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage"])},
  "form.command.usage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarkeit"])},
  "form.command.userPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer Periode"])},
  "form.command.version.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "form.counter-variable.counter.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zähler"])},
  "form.counter-variable.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.overlay-alert-part.gif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gif"])},
  "form.overlay-alert-part.sound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
  "form.overlay-alert-part.text.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.overlay-alert-part.time.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit in Sekunden"])},
  "form.overlay-alert-part.volume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lautstärke"])},
  "form.overlay-alert.fontSize.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftgröße"])},
  "form.overlay-alert.primaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primärfarbe"])},
  "form.overlay-alert.secondaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundärfarbe"])},
  "form.overlay-alert.shadow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textschatten"])},
  "form.overlay-alert.textTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text über GIF"])},
  "form.periodic-message.message.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
  "form.periodic-message.messagesThreshold.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderliche Nachrichten"])},
  "form.periodic-message.period.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode"])},
  "form.periodic-message.pinMessage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht anpinnen"])},
  "form.shop.settings.command.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlsetikett"])},
  "form.shop.settings.globalPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globaler Zeitraum"])},
  "form.shop.settings.userPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerzeitraum"])},
  "form.widget.animated.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animiert"])},
  "form.widget.animation.fab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotieren"])},
  "form.widget.animation.fade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einblenden"])},
  "form.widget.animation.floating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwebend"])},
  "form.widget.animation.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation"])},
  "form.widget.animation.scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergrössern"])},
  "form.widget.animation.slide-x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide von Links"])},
  "form.widget.animation.slide-x-reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide von Rechts"])},
  "form.widget.animation.slide-y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide von Oben"])},
  "form.widget.animation.slide-y-reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide von Unten"])},
  "form.widget.animation.snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnee"])},
  "form.widget.animationDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animationsdauer"])},
  "form.widget.appearance.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erscheinung"])},
  "form.widget.backgroundColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hintergrundfarbe"])},
  "form.widget.badges.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abzeichen anzeigen"])},
  "form.widget.bars.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balken"])},
  "form.widget.barsRatio.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balkenverhältnis"])},
  "form.widget.bold.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fett"])},
  "form.widget.border.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahmen"])},
  "form.widget.borderColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahmenfarbe"])},
  "form.widget.bots.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot-Nachrichten anzeigen"])},
  "form.widget.color.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
  "form.widget.cooldownPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abkühlungsphase (Sekunden)"])},
  "form.widget.defaultCommands.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Commands"])},
  "form.widget.displayDelay.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigeverzögerung"])},
  "form.widget.displayDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigedauer"])},
  "form.widget.duration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])},
  "form.widget.emoteSize.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emotegröße"])},
  "form.widget.flameIcon.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flamme"])},
  "form.widget.follow.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
  "form.widget.followActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "form.widget.followDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer (Sekunden)"])},
  "form.widget.followFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollbild Grafik"])},
  "form.widget.followGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
  "form.widget.followPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Position"])},
  "form.widget.followPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand links"])},
  "form.widget.followPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand oben"])},
  "form.widget.followSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
  "form.widget.followText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.followVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lautstärke"])},
  "form.widget.font.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftart"])},
  "form.widget.fontSize.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftgröße"])},
  "form.widget.goal.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
  "form.widget.horizontal.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
  "form.widget.host.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "form.widget.hostActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "form.widget.hostDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer (Sekunden)"])},
  "form.widget.hostFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollbild Grafik"])},
  "form.widget.hostGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
  "form.widget.hostPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Position"])},
  "form.widget.hostPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand links"])},
  "form.widget.hostPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand oben"])},
  "form.widget.hostSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
  "form.widget.hostText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.hostVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lautstärke"])},
  "form.widget.hypeTrainOverText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.ignoreBots.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bots ignorieren"])},
  "form.widget.ignoreCommands.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehle ignorieren"])},
  "form.widget.ignoreEmojis.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emojis ignorieren"])},
  "form.widget.items.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
  "form.widget.items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "form.widget.levelCompletedText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.levelGoals.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwierigkeitsgrad der Levelziele"])},
  "form.widget.limit.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
  "form.widget.loadOldMessages.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alte Nachrichten laden"])},
  "form.widget.max.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "form.widget.minStreak.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestserie"])},
  "form.widget.multiplier.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplikator"])},
  "form.widget.noSongSubTitle.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Song Untertitel"])},
  "form.widget.noSongTitle.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Song Titel"])},
  "form.widget.participateText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.pauseDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausendauer"])},
  "form.widget.permissions.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung"])},
  "form.widget.pill.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pille"])},
  "form.widget.primaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primärfarbe"])},
  "form.widget.readUsername.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzernamen lesen"])},
  "form.widget.removeMessages.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösche ältere Nachrichten"])},
  "form.widget.removeMessagesAfter.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delay (Sekunden)"])},
  "form.widget.roles.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
  "form.widget.rounded.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgerundet"])},
  "form.widget.secondaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekundärfarbe"])},
  "form.widget.sevenTV.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SevenTV Emotes anzeigen"])},
  "form.widget.shadow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schatten"])},
  "form.widget.showImage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild anzeigen"])},
  "form.widget.showNumbers.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlen anzeigen"])},
  "form.widget.showPercentage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozentsatz anzeigen"])},
  "form.widget.showProfilePicture.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbild anzeigen"])},
  "form.widget.showProgress.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt anzeigen"])},
  "form.widget.showTime.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigedauer"])},
  "form.widget.showVideo.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video anzeigen"])},
  "form.widget.showVotes.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmen anzeigen"])},
  "form.widget.split.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen"])},
  "form.widget.striped.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streifen"])},
  "form.widget.sub.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnent"])},
  "form.widget.subActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "form.widget.subDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer (Sekunden)"])},
  "form.widget.subFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollbild Grafik"])},
  "form.widget.subGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
  "form.widget.subGift.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschenkabo"])},
  "form.widget.subGiftActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "form.widget.subGiftDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer (Sekunden)"])},
  "form.widget.subGiftFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollbild Grafik"])},
  "form.widget.subGiftGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
  "form.widget.subGiftPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Position"])},
  "form.widget.subGiftPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand links"])},
  "form.widget.subGiftPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand oben"])},
  "form.widget.subGiftSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
  "form.widget.subGiftText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.subGiftVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lautstärke"])},
  "form.widget.subPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Position"])},
  "form.widget.subPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand links"])},
  "form.widget.subPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand oben"])},
  "form.widget.subSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
  "form.widget.subText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.subTitle.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untertitel"])},
  "form.widget.subVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lautstärke"])},
  "form.widget.subscriptionsText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.supportText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.switchTime.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechselintervall (Sekunden)"])},
  "form.widget.text.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.textColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textfarbe"])},
  "form.widget.textShadow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textschatten"])},
  "form.widget.textTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text oben"])},
  "form.widget.threshold.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwellenwert"])},
  "form.widget.ticksColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe der Markierungen"])},
  "form.widget.time.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit"])},
  "form.widget.title.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "form.widget.titleText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.trackTime.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit verfolgen"])},
  "form.widget.useIdentityColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsfarbe verwenden"])},
  "form.widget.voice.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme"])},
  "form.widget.volume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lautstärke"])},
  "form.widget.windowName.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenstername"])},
  "form.widget.words.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörter"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos"])},
  "gifted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschenkt"])},
  "giveaway.chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "giveaway.costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten:"])},
  "giveaway.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giveaway erstellen"])},
  "giveaway.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "giveaway.end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beenden"])},
  "giveaway.entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahmen"])},
  "giveaway.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
  "giveaway.export.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "giveaway.export.timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitstempel einfügen"])},
  "giveaway.inactivity-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivitäts-Timeout:"])},
  "giveaway.keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselwort:"])},
  "giveaway.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht:"])},
  "giveaway.no-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer werden aufgrund von Inaktivität nicht vom Gewinnspiel ausgeschlossen."])},
  "giveaway.reroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuauslosung"])},
  "giveaway.roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auslosung"])},
  "giveaway.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlosung"])},
  "giveaway.winners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinner"])},
  "global-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Einstellungen"])},
  "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückgehen"])},
  "go-premium-now-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt Premium werden"])},
  "graphic-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafik Design"])},
  "home.charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysieren Sie die Chat-Aktivität Ihres Publikums mit Kicklet"])},
  "home.charts-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visuelle Diagramme für detaillierte Einblicke in Befehle und Nachrichten"])},
  "home.gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endlose Galerie von Streaming-Inhalten"])},
  "home.gallery-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimieren Sie Ihren Stream mit unserer vielfältigen Sammlung vorprogrammierter Widgets und Befehle, einschließlich allem von Minispielen bis zu einzigartigen interaktiven Funktionen."])},
  "home.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer wechseln zu Kicklet."])},
  "home.js": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmiere benutzerdefinierte Befehle in JavaScript"])},
  "home.js-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbessere dein Chat-Erlebnis mit individuellen Skriptlösungen"])},
  "home.kicklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der beste Chatbot für Kick?"])},
  "home.kicklet-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kicklet wird als der beste Chatbot für Kick anerkannt, ideal für Streamer aller Stufen. Mit unseren innovativen Funktionen und anpassbaren Overlay-Widgets verbessern wir jeden Stream und stechen als Favorit der Community hervor."])},
  "home.offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was wir bieten:"])},
  "home.sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecke warum!"])},
  "home.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "home.trusted-streamers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstaunliche Streamer, die uns vertrauen"])},
  "home.what-is-kicklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist Kicklet?"])},
  "home.widgets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzigartige Overlay-Widgets"])},
  "home.widgets-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Widgets sind einzigartig und hochgradig anpassbar, sodass du deinem Stream eine eigene, unverwechselbare Identität verleihen kannst."])},
  "home.youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahre mehr über Kicklet in unserem YouTube-Video!"])},
  "home.youtube-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaue dir unser kurzes Video an, um ein Gefühl für Kicklet, unseren Chatbot, zu bekommen."])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BELIEBT"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren"])},
  "join-discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tritt unserem Discord bei!"])},
  "kick-video-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick Video URL"])},
  "kick.identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identität"])},
  "latest.events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Ereignisse"])},
  "latest.livestream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Livestream"])},
  "login-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung erforderlich"])},
  "login-required-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um auf diese Seite zuzugreifen, ist eine Anmeldung notwendig. Sie können sich einfach über Discord anmelden und loslegen."])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten"])},
  "moderation-rule.action.BAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
  "moderation-rule.action.DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "moderation-rule.action.TIMEOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "moderation-rule.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["die Regel <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span>"])},
  "moderation-rule.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel"])},
  "moderation-rule.no-longer-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Moderationsregel existiert nicht mehr!"])},
  "moderation-rule.trigger.CONTAINS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthält"])},
  "moderation-rule.trigger.ENDS_WITH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endet mit"])},
  "moderation-rule.trigger.EQUALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entspricht"])},
  "moderation-rule.trigger.REGEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulärer Ausdruck"])},
  "moderation-rule.trigger.SPAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spam"])},
  "moderation-rule.trigger.STARTS_WITH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginnt mit"])},
  "moderation-rule.trigger.UPPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Großbuchstaben"])},
  "moderation-rule.trigger.URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links blockieren"])},
  "moderation-rule.trigger.URL.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardmäßig sind alle Links blockiert. Du kannst unten Ausnahmen im Format \"kick.com\" hinzufügen"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
  "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschieben"])},
  "multi-stream.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaue gleichzeitig mehrere Streamer mit einem generierten Link. Perfekt für gemeinsames Streamen oder Gruppenspiele. Genieße nahtloses Mehrfach-Streaming."])},
  "multi-stream.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrfach-Stream"])},
  "navigation.commandsAndTimers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehle und Timer"])},
  "navigation.dataManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenverwaltung"])},
  "navigation.graphic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafikdesign"])},
  "navigation.help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
  "navigation.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "navigation.marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktplatz"])},
  "navigation.userInteraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerinteraktion"])},
  "navigation.utils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkzeuge"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEU"])},
  "no-collab-permission-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Streamer hat dir nicht die erforderlichen Berechtigungen gegeben, um das anzusehen."])},
  "no-latest-livestream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben seit der Aktivierung von Kicklet nicht gestreamt. Nach Ihrem ersten Stream werden Sie hier die Statistiken für Ihren Livestream sehen."])},
  "no-more-widgets-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine weiteren Widgets verfügbar"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])},
  "origin-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursprungsdaten"])},
  "origin-data-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haken setzen, um auf die ursprünglichen von Kicklet erfassten Daten zurückzusetzen; nicht markieren, um auf Null zurückzusetzen."])},
  "page-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden."])},
  "panels.buttons.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
  "panels.buttons.generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generieren"])},
  "panels.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unten kannst du alle Einstellungen für deine Panels anpassen, und mit nur einem Klick auf den 'Generieren'-Knopf, deine Panels ganz leicht herunterladen. Erstelle alle Panels, die du brauchst, in wenigen Sekunden und völlig kostenlos. Wenn du Vorschläge für unterschiedliche Formen, Schriftarten oder etwas anderes hast, zögere nicht, einen Vorschlag auf unserem Discord-Server zu machen."])},
  "panels.labels.font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schriftart"])},
  "panels.labels.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
  "panels.labels.panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
  "panels.labels.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "panels.labels.text-shadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schatten"])},
  "panels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Generator"])},
  "periodic-messages-editor.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodische Nachricht"])},
  "point-settings-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Punkte Einstellungen wurden gespeichert"])},
  "premium.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau:"])},
  "private-profile.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "profile.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehl"])},
  "profile.commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehle"])},
  "profile.permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
  "profile.profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "profile.ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangliste"])},
  "profile.song-queue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieder"])},
  "profile.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "profile.verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizieren"])},
  "public.profile.button.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliches Profil anzeigen"])},
  "public.profile.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliches Profil Einstellungen"])},
  "public.profile.toggle.commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehlsseite anzeigen"])},
  "public.profile.toggle.ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangliste anzeigen"])},
  "public.profile.toggle.songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liederwarteschlange anzeigen"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichen"])},
  "purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekauft"])},
  "ranking.no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten"])},
  "ranking.no-data-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine anzeigbaren Daten über die Zuschauer dieses Streamers"])},
  "ranking.no-streamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer nicht gefunden"])},
  "ranking.subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Zuschauer"])},
  "ranking.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🏆 Ranking 🏆"])},
  "recent-livestreams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Livestreams"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "save-20-anually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparen Sie 20% jährlich"])},
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
  "saved-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich gespeichert"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
  "search-username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzernamen suchen.."])},
  "search.no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Suche hat kein Ergebnis gegeben."])},
  "see-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr anzeigen"])},
  "settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "shop.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver Shop"])},
  "shop.copy-and-use-command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren und Befehl verwenden"])},
  "shop.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "shop.item.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "shop.item.action.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "shop.item.action.custom-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefiniertes Ereignis"])},
  "shop.item.action.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
  "shop.item.action.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
  "shop.item.action.message-conflict-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt einen Konflikt mit dieser Aktion."])},
  "shop.item.action.sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton"])},
  "shop.item.action.unique-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion kann nur einmal durchgeführt werden."])},
  "shop.item.actionDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionsdauer"])},
  "shop.item.actionVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionsvolumen"])},
  "shop.item.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "shop.item.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbefehl"])},
  "shop.item.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbeschreibung"])},
  "shop.item.event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelereignis"])},
  "shop.item.globalPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globaler Zeitraum"])},
  "shop.item.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbild"])},
  "shop.item.isOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfordert Bearbeitung"])},
  "shop.item.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
  "shop.item.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelname"])},
  "shop.item.preview-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschaubild"])},
  "shop.item.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelpreis"])},
  "shop.item.sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelton"])},
  "shop.item.stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsmenge"])},
  "shop.item.stockUnlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbegrenzter Bestand"])},
  "shop.item.userPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerzeitraum"])},
  "shop.items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
  "shop.no-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Beschreibung verfügbar"])},
  "shop.no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast noch keine Artikel hinzugefügt."])},
  "shop.orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen"])},
  "shop.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
  "shop.points.cost": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("price")), " Punkte"])},
  "shop.public.no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Streamer hat noch keine Artikel hinzugefügt."])},
  "shop.remaining.stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["übrig"])},
  "shop.sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])},
  "shop.sales.onlyPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur ausstehende Verkäufe"])},
  "shop.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop-Einstellungen"])},
  "shop.stats.items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
  "shop.stats.pointsSpent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgegebene Punkte"])},
  "shop.stats.sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])},
  "shop.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])},
  "stream.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Titel"])},
  "subscription-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Subscription wurde erfolgreich beendet."])},
  "terms-of-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
  "test-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert testen"])},
  "thanks-for-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank, dass du Kicklet unterstützt! Deine Subscription bedeutet uns viel und hilft uns, weiterhin großartige Inhalte zu liefern."])},
  "thanks-for-supporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke für Ihre Unterstützung bei Kicklet!"])},
  "title-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Titel wurde gespeichert."])},
  "try-it-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probier es aus!"])},
  "ungrouped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht gruppiert"])},
  "unsaved-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungespeicherte Änderungen"])},
  "unsaved-changes-content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben ungespeicherte Änderungen in <span class=\"color-primary\">", _interpolate(_named("title")), "</span>"])},
  "unverify.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung entfernen"])},
  "unverify.dialog.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, entfernen!"])},
  "unverify.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher?"])},
  "unverify.dialog.warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachdem du dein Kick-Konto entfernt hast, musst du dich erneut verifizieren"])},
  "unverify.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um ein anderes Konto zu verknüpfen, musst du dieses Konto zuerst entfernen."])},
  "unverify.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Konto ist mit einem Kick-Profil verknüpft"])},
  "update-from-kicklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung durch Kicklet"])},
  "upgrade-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt upgraden!"])},
  "upgrade-to-premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Premium upgraden:"])},
  "upgrade-to-premium-by-pressing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch Drücken der Taste auf Premium upgraden und mehr Funktionen sowie höhere Limits freischalten"])},
  "usage.activeGiveaways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Giveaways"])},
  "usage.assetsSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speicherplatz für Assets"])},
  "usage.collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
  "usage.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
  "usage.commandGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandgruppen"])},
  "usage.counterVariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zählervariablen"])},
  "usage.moderationRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderationsregeln"])},
  "usage.periodicMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodische Nachrichten"])},
  "usage.shopItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop-Artikel"])},
  "usage.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzung"])},
  "usage.variableStorage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speicherplatz für Variablen"])},
  "usage.widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widgets"])},
  "use-custom-file-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutze benutzerdefinierten Dateinamen"])},
  "user-lookup.buttons.copy-playback-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiedergabe-URL kopieren"])},
  "user-lookup.buttons.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "user-lookup.buttons.visit-on-kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Kick besuchen"])},
  "user-lookup.created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal erstellt"])},
  "user-lookup.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecke Details wie Follower, Erstellungsdatum des Kanals, Benutzer-ID und sogar die Wiedergabe-URL, wenn der Streamer gerade live ist. Hol dir Informationen mit unserer Benutzersuche-Funktion."])},
  "user-lookup.follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follower"])},
  "user-lookup.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "user-lookup.is-banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist gesperrt"])},
  "user-lookup.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzersuche"])},
  "user-lookup.user-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer-ID"])},
  "user-lookup.user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer nicht gefunden"])},
  "user-lookup.verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiziert"])},
  "user-switched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer erfolgreich gewechselt!"])},
  "validation.float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss eine Dezimalzahl sein"])},
  "validation.integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss eine ganze Zahl sein"])},
  "validation.length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Länge muss zwischen ", _interpolate(_named("min")), " und ", _interpolate(_named("max")), " liegen"])},
  "validation.max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Maximum für diesen Wert ist ", _interpolate(_named("max"))])},
  "validation.min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Minimum für diesen Wert ist ", _interpolate(_named("min"))])},
  "validation.no-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind keine Leerzeichen erlaubt"])},
  "validation.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss eine Zahl sein"])},
  "validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld wird benötigt"])},
  "validation.size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Größe muss zwischen ", _interpolate(_named("min")), " und ", _interpolate(_named("max")), " liegen"])},
  "verify.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt verifizieren"])},
  "verify.dialog.list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiere den Befehl unten."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne <a class='color-primary' href='https://kick.com/kicklet/chatroom' target='_blank'>https://kick.com/kicklet/chatroom</a>"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib den kopierten Befehl im Chatraum des Kanals von Kicklet ein."])}
  ],
  "verify.dialog.modList": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuerst musst du Kicklet als Moderator zu deinem Kick-Kanal hinzufügen."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne <a class='color-primary' href='https://kick.com/dashboard/community/moderators' target='_blank'>https://kick.com/dashboard/community/moderators</a>"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Und füge <span class='color-primary'>Kicklet</span> als Moderator zu deinem Kanal hinzu."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativ kannst du auch <span class='color-primary'>/mod Kicklet</span> in DEINEM Chat eingeben."])}
  ],
  "verify.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung"])},
  "verify.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diese Funktion zu nutzen, musst du mit deinem Kick.com-Konto verifiziert sein!"])},
  "verify.no-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verifiziert"])},
  "verify.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung erforderlich"])},
  "view-charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramme anzeigen"])},
  "visit-public-shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentlichen Shop besuchen"])},
  "vod-downloader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOD Downloader"])},
  "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lautstärke"])},
  "widget-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget-Steuerung"])},
  "widget.creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
  "widget.gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
  "widget.my-widgets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Widgets"])},
  "widgets.asset-manager.choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "widgets.asset-manager.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "widgets.asset-manager.edit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitungsmodus"])},
  "widgets.asset-manager.image-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Asset Manager"])},
  "widgets.asset-manager.library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek"])},
  "widgets.asset-manager.my-images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Bilder"])},
  "widgets.asset-manager.my-sounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Sounds"])},
  "widgets.asset-manager.play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abspielen"])},
  "widgets.asset-manager.sound-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound Asset Manager"])},
  "widgets.asset-manager.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "widgets.asset-manager.upload-dialog.drag-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hierher ziehen"])},
  "widgets.asset-manager.upload-dialog.drag-files-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder <strong><em>hier klicken</em></strong> um Dateien hochzuladen"])},
  "widgets.asset-manager.upload-dialog.drop-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hier ablegen"])},
  "widgets.asset-manager.upload-dialog.drop-files-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um sie hochzuladen"])},
  "widgets.asset-manager.upload-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild hochladen"])},
  "widgets.asset-manager.upload-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound hochladen"])},
  "widgets.buttons.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])},
  "widgets.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["das Widget <span class='color-primary'>", _interpolate(_named("name")), "</span>"])},
  "widgets.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "widgets.description.drag-obs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zieh mich in dein OBS"])},
  "widgets.description.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte, dass diese Anleitung davon ausgeht, dass du OBS bereits installiert hast und mit seinen Grundfunktionen vertraut bist. Lass es uns auf unserem Discord wissen, wenn du weitere Fragen hast!"])},
  "widgets.description.list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne dein OBS."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge eine neue Browserquelle hinzu."])},
    (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stelle die Breite auf ", _interpolate(_named("width")), " und die Höhe auf ", _interpolate(_named("height")), " ein."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge die kopierte URL in das URL-Feld ein."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke auf Okay."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platziere dein Widget nun nach Belieben, indem du es herumziehst."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viel Spaß mit deinem neuen Widget!"])}
  ],
  "widgets.description.method1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methode 1 (Super Einfach)"])},
  "widgets.description.method2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methode 2 (Die gute alte Methode)"])},
  "widgets.description.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie du das Widget zu OBS hinzufügst"])},
  "widgets.new-widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Widget"])},
  "widgets.no-widgets-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast noch kein Widget erstellt. Beginne jetzt, indem du den Store besuchst, um dein erstes Widget zu erstellen."])},
  "widgets.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widgets"])},
  "widgets.widget-import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget verwenden"])},
  "widgets.widget-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Name"])},
  "with-discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Discord"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DU"])},
  "your-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Plan:"])},
  "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variablen"])},
  "variables.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable erstellen"])},
  "variables.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["die Variable <span class='color-primary'>", _interpolate(_named("name")), "</span>"])},
  "variables.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "variables.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "variables.dialog.variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "variables.dialog.value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
  "variables.dialog.viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuschauer"])},
  "bot.variables.no-variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast derzeit keine Variablen. Beginne damit, eine neue zu erstellen."])},
  "viewer-variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuschauer Variablen"])},
  "discord-member-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
  "form.widget.showSpider.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spinne anzeigen"])},
  "form.widget.showGhost.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geist anzeigen"])},
  "select-and-install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen & Installieren"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen"])},
  "link-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link in die Zwischenablage kopiert!"])},
  "widget.preview.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "widget.preview.advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Widget ist"])},
  "no-data-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten verfügbar"])},
  "items-per-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente pro Seite"])},
  "of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("itemRange")), " von ", _interpolate(_named("length"))])},
  "backend.errors.alias already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias wird bereits in einem anderen Befehl verwendet."])},
  "bot.command-groups.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe auswählen"])},
  "locales.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "locales.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "locales.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch"])},
  "api-token.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie hier Ihre API-Tokens. Integrieren Sie diese beispielsweise mit dem"])}
}